<template>
    <div>
        <v-title v-if="Object.keys(response).length" :title="`${response.data.group.groupName} - Application Groups`"></v-title>

        <title-box>
            <div class="row">
                <div class="col-12">
                    <h1>Edit group</h1>
                </div>
            </div>
        </title-box>

        <form @submit.prevent="submitForm" v-if="form">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-7">
                        <mercur-card class="mt-n4 mb-4">
                            <mercur-input v-model="form.groupName" :class="getValidationClass($v, 'groupName')">
                                Applications group name
                                <template #note>
                                    <span class="form-error" v-if="!$v.form.groupName.required">Group name is required</span>
                                </template>
                            </mercur-input>
                        </mercur-card>
                        <mercur-card class="mb-4 card--scrollable">
                            <h2 class="mt-1 font-weight-normal">Applications</h2>
                            <table class="table table-bordered table-hover">
                                <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Application override</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="application in items.applications" :key="application.applicationId">
                                    <td>{{ application.applicationName }}</td>
                                    <td>
                                        <mercur-checkbox v-model="application.applicationStatus" :id="`application-${application.applicationId}`">Is allowed</mercur-checkbox>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </mercur-card>
                        <div class="text-right mt-4">
                            <mercur-button class="btn" to="/groups">Cancel</mercur-button>
                            <mercur-button class="btn btn-primary" type="submit" :disabled="loading">Update group</mercur-button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>
<script>
import ResourcesView from '@/views/ResourcesView'
import CONFIG from '@root/config'
import FormMixin from '@/mixins/Form'
import { required } from 'vuelidate/lib/validators'

export default {
    name: 'EditGroup',
    mixins: [ResourcesView, FormMixin],
    data () {
        return {
            url: CONFIG.API.ROUTES.APPLICATIONS_GROUPS.GET.replace('{groupId}', this.$route.params.groupId),
            action: CONFIG.API.ROUTES.APPLICATIONS_GROUPS.UPDATE,
            form: null,
            isNotApplicationBound: true,
        }
    },
    validations: {
        form: {
            groupName: {
                required,
            },
        },
    },
    watch: {
        response () {
            this.form = this.response.data.group
        },
    },
    computed: {
        groupApplications () {
            let arr = []
            this.items.applications.forEach((item) => {
                if (item.applicationStatus) {
                    arr.push(item.applicationName)
                }
            })
            return arr
        },
        breadCrumbId () {
            if (!this.form) {
                return ''
            }
            return this.form.groupName
        },
    },
    methods: {
        submitForm () {
            this.form.applications.applicationNames = this.groupApplications

            this.submit(this.action, this.form).then(() => {
                this.$root.$emit('notification:global', {
                    message: 'Group was updated',
                })
                setTimeout(() => {
                    this.$router.push({
                        name: 'GroupsWrapper',
                    })
                }, 1000)
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.card--scrollable {
    height: calc(100vh - 400px);
    overflow-y: auto;
}
</style>
